<template>
  <router-view class="full-height full-width"></router-view>
</template>

<script>

export default {
  name: 'HarvestFrontsView',
  components: {
  },
  props: {
  },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
.container, .v-tabs-items, .v-tabs, .v-item-group{
  height: 100% !important;
}
</style>
